<template>
  <div style="padding: 20px">
    <div>
      <el-row :gutter="20">
        <el-col :span="6" class="finance-top">
          <div class="top-label">平台</div>
          <el-select v-model="platform" placeholder="请选择">
            <el-option
              v-for="item in Object.keys(PlatformType)"
              :key="item"
              :label="PlatformType[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="finance-top">
          <div class="top-label">账户</div>
          <el-input v-model="account" placeholder="手机/账户名"></el-input>
        </el-col>
        <el-col :span="6" class="finance-top">
          <div class="top-label">账务类型</div>
          <el-select v-model="tx_type" placeholder="请选择">
            <el-option
              v-for="item in Object.keys(FinancialType)"
              :key="item"
              :label="FinancialType[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" class="finance-top">
          <div class="top-label">操作类型</div>
          <el-select v-model="business_type" placeholder="请选择">
            <el-option
              v-for="item in Object.keys(OptionType)"
              :key="item"
              :label="OptionType[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="finance-top">
          <div class="top-label">收支类型</div>
          <el-select v-model="io_type" placeholder="请选择">
            <el-option
              v-for="item in Object.keys(BalanceType)"
              :key="item"
              :label="BalanceType[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="finance-top">
          <div class="top-label">结算渠道</div>
          <el-select v-model="channel" placeholder="请选择">
            <el-option
              v-for="item in Object.keys(ChannelType)"
              :key="item"
              :label="ChannelType[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" class="finance-top">
          <div class="top-label">交易号</div>
          <el-input v-model="order_id" placeholder="请输入交易号"></el-input>
        </el-col>
        <el-col :span="18" class="finance-top">
          <div class="top-label">交易时间</div>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
          <div class="range-picker">
            <el-button type="text" size="default" @click="setDateRange(7)">近7天</el-button>
            <el-button type="text" size="default" @click="setDateRange(30)">近30天</el-button>
            <el-button type="text" size="default" @click="setDateRange(90)">近90天</el-button>
          </div>
          <div style="text-align: right;margin-left:80px">
            <el-button
              style="text-align: right"
              type="primary"
              size="default"
              @click="handleClickSearch"
            >
              查询
            </el-button>
            <el-button
              style="text-align: right"
              type="info"
              size="default"
              @click="handleClickReset"
            >
              重置
            </el-button>
            <el-button type="primary" size="default" @click="showAdd">
              创建支出单
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- :row-key="order_id" -->
    <el-table :data="list" border stripe>
      <el-table-column label="交易号" prop="order_id"></el-table-column>
      <el-table-column label="账户名" prop="nickname"></el-table-column>
      <el-table-column label="手机号" prop="mobile"></el-table-column>
      <el-table-column label="收支金额(元)" prop="value"></el-table-column>

      <el-table-column label="账务类型" prop="tx_type">
        <template slot-scope="scope">
          <div>{{ FinancialType[scope.row.tx_type] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间(UTC)" prop="timestamp">
        <template slot-scope="scope">
          <div>{{ scope.row.timestamp | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="getFinanceInfo(scope.row.order_id, scope.row.ucid)"
            style="margin-right: 10px"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="创建支出单"
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
      destroy-on-close
    >
      <div v-if="visible">
        <FinanceForm @success="handleSuccess" />
      </div>
    </el-dialog>

    <el-dialog
      title="支出单详情"
      :visible.sync="infoVisible"
      width="600px"
      @close="closeInfoDialog"
      destroy-on-close
    >
      <div v-if="infoVisible">
        <div class="info">
          <div class="info-lable">交易号</div>
          <div>{{ financeInfo.order_id }}</div>
        </div>
        <div class="info">
          <div class="info-lable">账户名</div>
          <div>{{ showValue(financeInfo.nickname) }}</div>
        </div>
        <div class="info">
          <div class="info-lable">手机号</div>
          <div>{{ showValue(financeInfo.mobile) }}</div>
        </div>
        <div class="info">
          <div class="info-lable">邮箱</div>
          <div>{{ showValue(financeInfo.email) }}</div>
        </div>
        <div class="info">
          <div class="info-lable">收支金额(元)</div>
          <div>{{ financeInfo.value }}</div>
        </div>
        <div class="info">
          <div class="info-lable">账务类型</div>
          <div>{{ FinancialType[financeInfo.tx_type] }}</div>
        </div>
        <div class="info">
          <div class="info-lable">收支类型</div>
          <div>{{ BalanceType[financeInfo.io_type] }}</div>
        </div>
        <div class="info">
          <div class="info-lable">结算渠道</div>
          <div>{{ ChannelType[financeInfo.channel] }}</div>
        </div>
        <div class="info">
          <div class="info-lable">结算账号</div>
          <div>{{ showValue(financeInfo.seal_account) }}</div>
        </div>
        <div class="info">
          <div class="info-lable">身份证</div>
          <div>{{ showValue(financeInfo.id_number) }}</div>
        </div>
        <div class="info">
          <div class="info-lable">创建时间</div>
          <div>{{ financeInfo.create_time | formatDate }}</div>
        </div>
        <div class="info">
          <div class="info-lable">平台</div>
          <div>{{ PlatformType[financeInfo.platform] }}</div>
        </div>
        <div class="info">
          <div class="info-lable">附件</div>
          <div class="info-group">
            <div v-for="(item, index) in financeInfo.attach_datas" :key="index">
              <img :src="item" width="100" style="margin-right: 10px" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import FinanceForm from "./financeForm.vue";
import { getFinanceList, getFinanceInfo } from "@/api/nft.js";
import {
  PlatformType,
  FinancialType,
  OptionType,
  BalanceType,
  ChannelType,
  transformTime,
} from "@/utils/helper.js";

const dateFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

const getDateRange = (day) => {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * day);
  end.setTime(end.getTime() - 3600 * 1000 * 24);
  return [dateFormat(start), dateFormat(end)];
};

export default {
  components: { FinanceForm },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      status: "all",
      list: [],
      visible: false,
      infoVisible: false,
      financeInfo: {},
      rules: {},
      dateRange: [],
      PlatformType,
      FinancialType,
      OptionType,
      BalanceType,
      ChannelType,

      platform: "",
      io_type: "",
      business_type: "",
      channel: "",
      order_id: "",
      start: "",
      end: "",
      account: "",
      tx_type: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return transformTime(value * 1000);
    },
  },
  mounted() {
    // this.setDateRange(7);
    this.fetchData();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };
      this.platform && (params.platform = +this.platform);
      this.io_type && (params.io_type = +this.io_type);
      this.business_type && (params.business_type = +this.business_type);
      this.channel && (params.channel = +this.channel);
      this.order_id && (params.order_id = this.order_id);
      this.dateRange[0] &&
        (params.start = dayjs(this.dateRange[0])
          .startOf("day")
          .unix());
      this.dateRange[1] &&
        (params.end = dayjs(this.dateRange[1])
          .endOf("day")
          .unix());
      this.account && (params.account = this.account);
      this.tx_type && (params.tx_type = +this.tx_type);

      return params;
    },

    async fetchData() {
      try {
        const params = this.getSearchParams();
        const { data } = await getFinanceList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async getFinanceInfo(id, ucid) {
      try {
        const params = {
          order_id: id,
          ucid: ucid,
        };
        const { data } = await getFinanceInfo(params);
        this.financeInfo = data?.data;
        this.infoVisible = true;
      } catch (error) {
        console.log(error);
      }
    },
    showValue(str) {
      return str ? str : "--";
    },
    handleClickSearch() {
      this.page = 1;
      this.fetchData();
    },
    closeInfoDialog() {
      this.infoVisible = false;
      this.financeInfo = {};
    },
    closeDialog() {
      this.visible = false;
    },
    setDateRange(day) {
      this.dateRange = getDateRange(day);
    },
    // 输入搜索值
    changeSearch(value) {
      console.log(value);
    },

    filterMethod(query, item) {
      return item.name.indexOf(query) > -1;
    },
    handleClickReset() {
      this.resetQuery();
      this.fetchData();
    },
    resetQuery() {
      this.page = 1;
      this.page_size = 20;
      this.platform = "";
      this.io_type = "";
      this.business_type = "";
      this.channel = "";
      this.order_id = "";
      this.start = "";
      this.end = "";
      this.account = "";
      this.tx_type = "";
      this.dateRange = [];
    },
    showAdd() {
      this.visible = true;
      this.optionType = "add";
      this.editFinance = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
    handleSuccess() {
      this.page = 1;
      this.fetchData();
      this.closeDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.finance-top,
.info {
  display: flex;
  align-items: center;
  .top-label,
  .info-lable {
    min-width: 80px;
  }
}
.info {
  margin-bottom: 10px;
  align-items: flex-start;
}
.info-lable {
  padding: 0 20px;
}
.range-picker {
  margin-left: 10px;
}
.info-group {
  display: flex;
  flex-wrap: wrap;
}
.el-row {
  margin-bottom: 20px;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}
</style>
