<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="100px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="总金额" prop="amount">
        <el-input
          v-model="form.amount"
          placeholder="请输入总金额"
          maxlength="10"
          type="number"
        ></el-input>
      </el-form-item>

      <el-form-item label="用户账户" prop="account" class="input-with-select">
        <el-input v-model="form.account" placeholder="请输入用户账户">
          <el-select v-model="form.mobile_prefix" slot="prepend" placeholder="请选择">
            <el-option
              v-for="item in COUNTRY_LIST"
              :key="item.country_id"
              :label="item.country_name_cn"
              :value="item.country_code"
            >
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>

      <el-form-item label="平台" prop="platform">
        <el-select v-model="form.platform" placeholder="请选择">
          <el-option
            v-for="item in Object.keys(PlatformType).filter((x) => x)"
            :key="item"
            :label="PlatformType[item]"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="结算渠道" prop="channel">
        <el-select v-model="form.channel" placeholder="请选择">
          <el-option
            v-for="item in Object.keys(ChannelType).filter((x) => x)"
            :key="item"
            :label="ChannelType[item]"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="上传附件" prop="attach_datas">
        <el-upload
          multiple
          :limit="10"
          :file-list="picList"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :before-upload="beforeUpload"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          accept="image/png,image/jpg,image/jpeg"
          action="https://www.ddpurse.com/uploadImage"
        >
          <el-button size="small" type="primary" @click="submitUpload">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传JPG\PNG\JPEG文件，且不超过10M</div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addFinance } from "@/api/nft.js";
import { PlatformType, ChannelType } from "@/utils/helper.js";
import { COUNTRY_LIST } from "@/utils/country.js";

export default {
  props: {
    data: {
      default: () => {},
    },
  },
  data() {
    return {
      PlatformType,
      ChannelType,
      COUNTRY_LIST,
      form: {
        mobile_prefix: "+86",
      },
      rules: {
        amount: [{ required: true, message: "请输入总金额", trigger: "blur" }],
      },
      imgList: [],
    };
  },
  mounted() {
    console.log("mounted", this.data);
  },
  computed: {
    picList() {
      if (this.form.attach_datas) {
        return [
          {
            url: this.form.attach_datas,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    async submit() {
      const action = this.add;
      const success = await action();
      if (success) {
        this.$emit("success");
      }
    },

    async add() {
      try {
        const financeData = this.getFinanceData();
        console.log("submit", financeData);
        await addFinance(financeData);
        return true;
      } catch (error) {
        return false;
      }
    },
    getFinanceData() {
      let json = {
        account: this.form.account,
        mobile_prefix: this.form.mobile_prefix,
        amount: this.form.amount,
        platform: +this.form.platform,
        channel: +this.form.channel,
        attach_datas: this.imgList, //.toString()
      };
      return json;
    },
    // 上传方法
    beforeUpload(file) {
      // console.log("before upload");
      console.log(file);
      let size = file.size / 1024 / 1024;
      return new Promise((resolve, reject) => {
        if (size > 10) {
          this.$message.warning("文件大小不得超过10M");
          reject();
        } else {
          resolve(true);
        }
      });
    },
    handleRemove(file, fileList) {
      // todo 并不能删除
      this.imgList.filter((x) => !x.includes(file.name));
      console.log(file, fileList, this.imgList, Object.values(this.picList).url);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 10 个文件，本次选择数量超出上限`);
    },
    handleSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.imgList.push(data.data);
      }
    },
    submitUpload() {
      console.log("this.fileList", this.fileList);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang="less">
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
